import Rivara61 from '../../../Media/Altea/L7/L7 (1).webp'
import Rivara62 from '../../../Media/Altea/L7/L7 (2).webp'
import Rivara63 from '../../../Media/Altea/L7/L7 (3).webp'
import Rivara64 from '../../../Media/Altea/L7/L7 (4).webp'
import Rivara65 from '../../../Media/Altea/L7/L7 (5).webp'
import Rivara66 from '../../../Media/Altea/L7/L7 (6).webp'
import Rivara67 from '../../../Media/Altea/L7/L7 (7).webp'
import Rivara68 from '../../../Media/Altea/L7/L7 (8).webp'
import Rivara69 from '../../../Media/Altea/L7/L7 (9).webp'
import Rivara610 from '../../../Media/Altea/L7/L7 (10).webp'
import Rivara611 from '../../../Media/Altea/L7/L7 (11).webp'



const Rivara6images =[ 
    Rivara61,Rivara62,Rivara63,Rivara64,Rivara65,Rivara66,Rivara67,Rivara68,Rivara69,Rivara610,Rivara611
]

export default Rivara6images;