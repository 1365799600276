import Rivara81 from '../../../Media/Altea/L8/L8 (1).webp'
import Rivara82 from '../../../Media/Altea/L8/L8 (2).webp'
import Rivara83 from '../../../Media/Altea/L8/L8 (3).webp'
import Rivara84 from '../../../Media/Altea/L8/L8 (4).webp'
import Rivara85 from '../../../Media/Altea/L8/L8 (5).webp'
import Rivara86 from '../../../Media/Altea/L8/L8 (6).webp'
import Rivara87 from '../../../Media/Altea/L8/L8 (7).webp'
import Rivara88 from '../../../Media/Altea/L8/L8 (8).webp'
import Rivara89 from '../../../Media/Altea/L8/L8 (9).webp'
import Rivara810 from '../../../Media/Altea/L8/L8 (10).webp'
import Rivara811 from '../../../Media/Altea/L8/L8 (11).webp'




const Rivara8images =[
Rivara81,Rivara82,Rivara83,Rivara84,Rivara85,Rivara86,Rivara87,Rivara88,Rivara89,Rivara810,Rivara811
]

export default Rivara8images;