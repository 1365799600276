import React from "react";
import "./rumah.scss";

import Produk1 from "./Produk1/Produk1.jsx";

const settings1 = {
  dots: true,
  arrows: true,
  infinite: true,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const rumah = () => {
  return (
    <div id="rumah" className="rumah">
      <div>
        <div className="Judul">OUR HOME IN ALTEA BOULEVARD</div>
        <div className="rumah-card">
          <Produk1 />
        </div>
      </div>
    </div>
  );
};

export default rumah;
