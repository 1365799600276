import React from "react";
import "./Produk1.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import Rivara6images from "./Rivara6";
import Rivara7images from "./Rivara7";
import Rivara8images from "./Rivara8";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowsUpDownLeftRight,
  faLocationDot,
  faMedal,
} from "@fortawesome/free-solid-svg-icons";
import {faHouse} from "@fortawesome/free-solid-svg-icons";
import {faBed} from "@fortawesome/free-solid-svg-icons";
import {faShower} from "@fortawesome/free-solid-svg-icons";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";

const Produk1 = () => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const O8wa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282213501915&text=Halo+Albert,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20Altea%20Boulevard%20(Rivara%206)%20https://marketing-alteablvd.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Tanamaswa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282213501915&text=Halo+Albert,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20Altea%20Boulevard%20(Rivara%207)%20https:/marketing-alteablvd.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };
  const Zlivwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282213501915&text=Halo+Albert,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20rumah%20Altea%20Boulevard%20(Rivara%208)%20https://marketing-alteablvd.com//&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="container">
      <div className="containercard2">
        <div className="card">
          <Slider {...settings}>
            {Rivara6images.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Rivara 6</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="#ad7e36"
                icon={faMedal}
              />
              <div className="namalokasi">Soft DP</div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#091c34" icon={faArrowsUpDownLeftRight} />
            <span> 72m²</span>
            <FontAwesomeIcon color="#091c34" icon={faHouse} />
            <span> 123m²</span>
            <FontAwesomeIcon color="#091c34" icon={faBed} />
            <span> 3</span>
            <FontAwesomeIcon color="#091c34" icon={faShower} />
            <span> 3</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">8 Juta/</div>
              <div className="ket">Bulan</div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={O8wa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Rivara7images.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Rivara 7</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="#ad7e36"
                icon={faMedal}
              />
              <div className="namalokasi">Soft DP</div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#091c34" icon={faArrowsUpDownLeftRight} />
            <span> 84m²</span>
            <FontAwesomeIcon color="#091c34" icon={faHouse} />
            <span> 143m²</span>
            <FontAwesomeIcon color="#091c34" icon={faBed} />
            <span> 3</span>
            <FontAwesomeIcon color="#091c34" icon={faShower} />
            <span> 3</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">14 Juta/</div>
              <div className="ket">Bulan</div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Tanamaswa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
        <div className="card">
          <Slider {...settings}>
            {Rivara8images.map((image, index) => (
              <img className="imagecard" key={index} src={image} />
            ))}
          </Slider>
          <div className="container-rumah">
            <div className="nama-rumah">Rivara 8</div>
            <div className="gridmap">
              <FontAwesomeIcon
                className="icon"
                color="#ad7e36"
                icon={faMedal}
              />
              <div className="namalokasi">Soft DP</div>
            </div>
          </div>
          <hr />
          <div className="gridspek">
            <FontAwesomeIcon color="#091c34" icon={faArrowsUpDownLeftRight} />
            <span> 170m²</span>
            <FontAwesomeIcon color="#091c34" icon={faHouse} />
            <span> 192m²</span>
            <FontAwesomeIcon color="#091c34" icon={faBed} />
            <span> 4</span>
            <FontAwesomeIcon color="#091c34" icon={faShower} />
            <span> 4</span>
          </div>
          <hr />
          <div className="containercicilan">
            <div className="judulkartu">
              <div className="startfrom">Start From</div>
            </div>
            <div className="cicilansumban">
              <div className="angka">19 Juta/</div>
              <div className="ket">Bulan</div>
            </div>
          </div>
          <div className="containerwhatsapp">
            <button onClick={Zlivwa} className="whatsappsumban">
              <FontAwesomeIcon icon={faWhatsapp} /> Whatsapp
            </button>
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default Produk1;
