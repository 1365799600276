import Rivara71 from '../../../Media/Altea/L6/L6 (1).webp'
import Rivara72 from '../../../Media/Altea/L6/L6 (2).webp'
import Rivara73 from '../../../Media/Altea/L6/L6 (3).webp'
import Rivara74 from '../../../Media/Altea/L6/L6 (4).webp'
import Rivara75 from '../../../Media/Altea/L6/L6 (5).webp'
import Rivara76 from '../../../Media/Altea/L6/L6 (6).webp'
import Rivara77 from '../../../Media/Altea/L6/L6 (7).webp'
import Rivara78 from '../../../Media/Altea/L6/L6 (8).webp'
import Rivara79 from '../../../Media/Altea/L6/L6 (9).webp'


const Rivara7images =[
Rivara71,Rivara72,Rivara73,Rivara74,Rivara75,Rivara76,Rivara77,Rivara78,Rivara79
]

export default Rivara7images;