import React from "react";
import "./about.scss";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import logoeonna from "../../Media/logo_altea (2).png";

const abouteonna = () => {
  const aboutwa = () => {
    const whatsappLink =
      "https://api.whatsapp.com/send/?phone=6282213501915&text=Halo%20Albert,%20Saya%C2%A0bisa%20minta%20detail%20Katalog,%20Simulasi%20dan%20Promo%20terbaru%20Rumah%20https://marketing-alteablvd.com/%20()&type=phone_number&app_absent=0";
    window.location.href = whatsappLink;
  };

  return (
    <div className="about">
      <div id="about" className="container-about">
        <div className="heading-about">
          <div className="logo">
            <img className="gambareonna" src={logoeonna} alt="logo eonna" />
          </div>
          <div className="judul2">The Jewel of Cibubur</div>
          <div className="desk">
            Altea Boulevard Cibubur (Altea BLVD) adalah sebuah proyek perumahan
            modern yang terletak di kawasan Cibubur, Jakarta. Berdiri di atas
            lahan seluas 75 hektar, proyek ini dikembangkan oleh kerjasama
            antara Astra Land dan Sinar Mas Land. Altea BLVD dirancang sebagai
            kawasan township modern yang terintegrasi dengan lingkungan alam,
            termasuk lembah hijau dan sungai. Proyek ini memiliki konsep
            residensial yang memadukan keindahan alam dengan fasilitas komersial
            yang lengkap.Terletak di jalan utama Alternatif Cibubur, Altea BLVD
            memiliki akses yang sangat strategis. Proyek ini berjarak sekitar 1
            menit dari Pintu Keluar Tol Jatikarya dan 2 km dari stasiun LRT
            Harjamukti.
          </div>
          <div className="button">
            <button className="wa" onClick={aboutwa}>
              <FontAwesomeIcon icon={faWhatsapp} />
              &nbsp; More Info
            </button>
          </div>
        </div>
        <div className="gambarabout"></div>
      </div>
    </div>
  );
};

export default abouteonna;
